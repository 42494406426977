import { useState, useContext, forwardRef, useCallback, useEffect } from "react";
import { Field, ValueDisplay } from "./field";
import { MiniLegendInverted, SmallLegend, ParchSideLegend } from "./legend";
import editIcon from "../../assets/img/edit1.png";
import zoomoutBtn from "../../assets/img/minus.png";
import zoominBtn from "../../assets/img/plus.png";
import saveIcon from "../../assets/img/coffre.png";
import deleteIcon from "../../assets/img/swords.png";
import downloadIcon from '../../assets/img/coffre.png';
import { IconBtn } from "./buttons";
import { pressBtnClass } from "./buttons";
import { FlourishLight } from "./icons";
import { NavContext } from "../context";
import { getCroppedImg } from "../utils";
import Cropper from 'react-easy-crop';

export const MainArticle = forwardRef(({ children, extraClass }, ref) => {
  return (
    <>
      <article ref={ref} className={`${extraClass} text-light w-full flex flex-col items-center`}>
        {children}
        <FlourishLight width="32" height="16"/>
      </article>
    </>
  );
});

export const ParchHorizonticle = ({ children }) => {
  return (
    <>
      <Horizonticle extraClass="mt-2 bg-parchment bg-center text-black border-stone shadow-sm shadow-black w-[90%]">
        {children}
      </Horizonticle>
    </>
  );
};

export const Horizonticle = ({ children, extraClass }) => {
  return (
    <>
      <article className={`${extraClass} flex flex-row items-center`}>
        {children}
      </article>
    </>
  );
};

export const ParchmentArticle = ({ children, extraClass }) => {

  const moreClass = extraClass ? extraClass : "";

  return (
    <>
      <article className={`${moreClass} relative font-written bg-parchment text-black flex flex-col border-stone shadow-sm shadow-black`}>
        {children}
      </article>
    </>
  );
}

export const StoneImageArticle = ({ picture, extraClass, onClick, isLoading, size }) => {

  const classLoading = isLoading ? "animate-pulse" : "";
  
  const moreClass = onClick ? pressBtnClass : "";

  const bgSize = size ? size : "cover";

  const style = picture ? { backgroundImage: `url(${picture})`, backgroundSize: bgSize } : {};

  return (
    <><article className={`${extraClass} ${classLoading} bg-parchment rounded-xl bg-repeat`} onClick={onClick} style={style}>
      <article className={`${moreClass} absolute w-full h-full border-stone`}>
      </article>
      </article>
    </>
  )
}

export const ZoomImageArticle = ({ picture, extraClass, isLoading, title, onCropSave = null, size }) => {

  const { setShowPopup, setPopupContent, setPopupType, setPopupCloseable, setPopupTitle, setPopupMenu } = useContext(NavContext);

  const [cropMode, setCropMode] = useState(false);
  const [zoom, setZoom] = useState(1.0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });

  const onCropChange = (newCrop) => {
      setCrop(newCrop);
  };

  const onCropComplete = useCallback((croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onCropCompleteInternal = useCallback((croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
      onCropComplete(croppedAreaPixels);
  }, [onCropComplete]);

  const handleSave = useCallback(async () => {
    const croppedFile = await getCroppedImg(picture, croppedAreaPixels);    
    setShowPopup(false);
    await onCropSave(title, [croppedFile]);
  }, [
    croppedAreaPixels,
    onCropSave,
    picture,
    title,
    setShowPopup
  ]);

  const setNonCropContent = useCallback(() => {
    setPopupContent(
      <>
      <StoneImageArticle picture={picture} extraClass="w-80 h-80 md:w-[40rem] md:h-[40rem]"/>
      <div className="w-full absolute top-1.5 items-center">
      <MiniLegendInverted extraClass="mx-auto">{title}</MiniLegendInverted>
      </div>
      </>
    );
    setPopupMenu(
      <>
        <IconBtn title="Download" extraClass="w-6 h-6" func={async () => {
            try {
                const response = await fetch(picture, { credentials: 'include' });
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'image.png'; // Optional: specify a file name
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url); // Free up memory
            } catch (error) {
                console.error('Image download failed:', error);
            }
        }}>                    
            <img src={downloadIcon} alt="download"/>
        </IconBtn>
        {onCropSave && <IconBtn title="Edit" extraClass="w-6 h-6" func={()=>setCropMode(true)}>
            <img src={editIcon} alt="edit"/>
        </IconBtn>}
      </>
    );
  }, [
    onCropSave,
    picture,
    setPopupContent,
    setPopupMenu,
    title
  ]);

  useEffect(() => {
    if (!cropMode) {
      setNonCropContent();
    } else {
      setPopupContent(
        <div className="relative w-80 h-80 md:w-[40rem] md:h-[40rem]">
            <div className="w-full h-full">
            <Cropper
            image={picture}
            crop={crop}
            zoom={zoom}
            aspect={1}  // Adjust the aspect ratio as needed
            onCropChange={onCropChange}
            onZoomChange={setZoom}
            onCropComplete={onCropCompleteInternal}
            />
            </div>
        </div>
      );
      setPopupMenu(
        <>
              
              <IconBtn title="Zoom Out" extraClass="w-6 h-6" func={()=>{
                if (zoom >= 1.1) {
                  setZoom(zoom*0.9)
                } else {
                  setZoom(1.0);
                }              
              }}>
                  <img src={zoomoutBtn} alt="zoom out"/>
              </IconBtn>
              <IconBtn title="Save" extraClass="w-6 h-6" func={handleSave}>
                  <img src={editIcon} alt="save"/>
              </IconBtn>
              <IconBtn title="Zoom In" extraClass="w-6 h-6" func={()=>setZoom(zoom*1.1)}>
                  <img src={zoominBtn} alt="zoom in"/>
              </IconBtn>
        </>
      )
    }
  }, [
    crop, cropMode, handleSave, onCropCompleteInternal, picture, setNonCropContent, setPopupContent, setPopupMenu, zoom
  ]);

  const onClick = () => {
    if (cropMode) {
      setCropMode(false);
      setCrop({ x: 0, y: 0 });
      setZoom(1.0);
    } else {
      setNonCropContent();
    }
    setPopupTitle(title);
    setPopupType("image");
    setShowPopup(true);
    setPopupCloseable(true);
  }

  return <StoneImageArticle picture={picture} extraClass={extraClass} isLoading={isLoading} size={size} onClick={onClick}/>

}


export const MyImageArticle = ({ children, picture, extraClass, onClick, isLoading, size }) => {

  const classLoading = isLoading ? "animate-pulse" : "";

  const moreClass = onClick ? pressBtnClass : "";

  const bgSize = size ? size : "cover";

  const style = picture ? { backgroundImage: `url(${picture})`, backgroundSize: bgSize } : {};

  return (
      <article className={`${extraClass} ${moreClass} ${classLoading} bg-parchment bg-repeat`} style={style} onClick={onClick}>
        {children}
      </article>
  )
}

export const SettingArticle = ({ extraClass, handleSubmit, item, permission, horizontal=false }) => {

  const [formMode, setFormMode] = useState(false);

  const handleEditClick = () => {
    if (formMode) {
      const e = new Event("submit");
      handleSubmit(e);
    }
    setFormMode(!formMode);
  };

  if (horizontal) {
    return (
      <>
      <ParchHorizonticle>
        <ParchSideLegend>{item.title}</ParchSideLegend>
        <Field formMode={formMode} handleSubmit={handleSubmit} item={item}>
          <p className="p-1 truncate">{(item.type === "select") ? <>{item.options.find(option => option.value === item.value)?.label}</> : <>{item.value}</>}</p>
        </Field>        
      </ParchHorizonticle>
      <menu className="flex flex-row justify-end absolute -bottom-7 w-full">
      {permission && <IconBtn title="Edit" func={handleEditClick} extraClass="w-6 h-6"><img src={editIcon} alt="edit"/></IconBtn>}
      </menu>
      </>
    );
  }

  return (
    <>
      <InvisArticle extraClass={`${extraClass} pb-2.5`}>
      <ParchmentArticle>
        <SmallLegend>{item.title}</SmallLegend>
        <Field formMode={formMode} handleSubmit={handleSubmit} item={item}>
          <p className="p-1 truncate">{(item.type === "select") ? <>{item.options.find(option => option.value === item.value)?.label}</> : <>{item.value}</>}</p>
        </Field>
      </ParchmentArticle>      
        <menu className="flex flex-row justify-end absolute bottom-0 w-full px-2.5">
          {permission && <IconBtn title="Edit" func={handleEditClick} extraClass="w-6 h-6"><img src={editIcon} alt="edit"/></IconBtn>}
        </menu>
      </InvisArticle>
    </>
  );
}

export const MultiSettingArticle = ({ items, handleSubmit, handleDelete, permission }) => {

  const [formMode, setFormMode] = useState(false);

  return (
    <InvisArticle extraClass="pb-2.5">
    <ParchmentArticle>
      <table className="bg-future">
        <tbody className="text-sm">
      {items.map((item, index) => (
        <tr key={index} className="border-b-2 border-transparent last:border-b-0">
          <td className="bg-primary text-light px-1 border-r-2 border-r-transparent text-right w-24">
            {item.title}
          </td>
          <td className="px-1 bg-parchment rounded-md">
            <Field formMode={formMode} handleSubmit={handleSubmit} item={item}><ValueDisplay item={item}/></Field>
          </td>
        </tr>        
      ))}</tbody></table>  
    </ParchmentArticle>
      <menu className="flex flex-row justify-end absolute bottom-0 px-2.5 w-full">
          {(permission && formMode) && <IconBtn title="Save" func={() => {
            handleSubmit();
            setFormMode(false);
          }} extraClass="w-6 h-6"><img src={saveIcon} alt="save"/></IconBtn>}
          {(permission && !formMode) && <IconBtn title="Edit" func={() => setFormMode(true)} extraClass="w-6 h-6"><img src={editIcon} alt="edit"/></IconBtn>}
          {permission && <IconBtn title="Delete" func={handleDelete} extraClass="w-6 h-6"><img src={deleteIcon} alt="delete"/></IconBtn>}
      </menu>
    </InvisArticle>
  );
}

export const InvisArticle = ({ children, extraClass }) => {
  return (
    <>
      <article className={`relative ${extraClass}`}>
        {children}
      </article>
    </>
  );
}

