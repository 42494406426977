import { CloseBtnStd, pressBtnClass } from "./buttons";

export const SmallLegend = ({ children, close, closeFunc, extraClass }) => {
  return (
    <>
      <legend className={`${extraClass} text-light font-superserif px-2 text-md shadow-sm relative shadow-black bg-darker rounded-t-md border-b-future`}>
        {close && <CloseBtnStd func={closeFunc} extraClass="absolute -right-1 -top-1"/>}
        {children}
      </legend>
    </>
  );
};

export const MiniLegend = ({ children, extraClass, onClick }) => {

  const moreClass = onClick ? pressBtnClass : "";

  return (
    <>
      <legend className={`${extraClass} text-light w-fit px-1 pt-1 text-center text-md bg-darker rounded-t-md border-b-future shadowy ${moreClass}`} onClick={onClick}>
        {children}
      </legend>
    </>
  );
};

export const MiniLegendInverted = ({ children, extraClass, onClick }) => {

  const moreClass = onClick ? pressBtnClass : "";

  return (
    <>
      <legend className={`${extraClass} text-light w-fit px-1 text-center text-md bg-darker rounded-b-md shadowy ${moreClass}`} onClick={onClick}>
        {children}
      </legend>
    </>
  );
};

export const ParchSideLegend = ({ children, extraClass }) => {
  return (
    <>
      <legend className={`${extraClass} text-light font-superserif text-md w-[50%] min-w-[50%] relative bg-darker rounded-l-sm border-r-future pr-2 mr-1 flex flex-row items-center justify-end`}>
        {children}
      </legend>
    </>
  );
}

export const InvisLegend = ({ children, extraClass }) => {
  return (
    <>
      <legend className={`${extraClass} font-superserif text-lg relative border-b-future mt-2`}>
        {children}
      </legend>
    </>
  );
}