import { createContext, useState, useCallback, useEffect, useContext } from 'react';
import { 
    getCurrentUserGroups,
    getIsMaster,
    getIsMember,
    getGroupMembers,
    postGenerateInviteCode, 
    getGroupDetails,
    getPublicGroups,
    postCreateGroup,
    postDescriptionDocument,
    postJoinGroup,postGenerateGroupBackground,postTransferCreditsToGroup,postTransferCreditsToMember,
    getInviteCode,
    putGroup,
    deleteGroupWithId,
    deleteDescriptionDocument,
    getLatestDocuments
} from './comms';
import { UserContext } from '../auth/context';
import { TemplatesContext } from '../templates/context';

export const GroupsContext = createContext();

export const GroupsProvider = ({ children }) => {
    const { currentUser, isStaff } = useContext(UserContext);
    const { selectedTemplate } = useContext(TemplatesContext);
    const [myGroups, setMyGroups ] = useState([]);
    const [publicGroups, setPublicGroups ] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [groupTemplate, setGroupTemplate] = useState(null);
    const [groupMembers, setGroupMembers] = useState([]);
    const [groupCredits, setGroupCredits] = useState(0);
    const [groupFiles, setGroupFiles] = useState([]);
    const [inviteCode, setInviteCode] = useState(null);
    const [latestDocuments, setLatestDocuments] = useState([]);
    const [isMember, setIsMember] = useState(false);
    const [isMaster, setIsMaster] = useState(false);

    const refreshLatestDocuments = useCallback(async (id) => {
        const files = await getLatestDocuments(id);
        setLatestDocuments(files);
        return files;        
    }, []);

    const setVariables = useCallback(async (group) => {
        setGroupCredits(group.credits);
        setGroupFiles(group.files);
        setGroupTemplate(group.Template);
        const isMember = isStaff || await getIsMember(group.id);
        setIsMember(isMember);
        const isMaster = isStaff || (isMember && await getIsMaster(group.id));
        setIsMaster(isMaster);
        if (isMember) {
            const members = await getGroupMembers(group.id);
            setGroupMembers(members);
            const inviteCode = await getInviteCode(group.id);
            setInviteCode(inviteCode);
        }
        await refreshLatestDocuments(group.id);        
    }, [
        isStaff,
        setGroupMembers,
        setInviteCode,
        setGroupCredits,
        setGroupTemplate,
        setIsMember,
        setIsMaster,
        refreshLatestDocuments
    ]);

    const createGroup = useCallback(async (title, description, templateId, type, pictureStyle) => {
        const group = await postCreateGroup(title, description, templateId, type, pictureStyle);
        if (group) {
            setSelectedGroup(group);
        }
        return group;
    }, []);

    const joinGroup = useCallback(async (code) => {
        const group = await postJoinGroup(code);
        if (group) {
            setSelectedGroup(group);
            setMyGroups(myGroups => [...myGroups, group]);
        }
        return group;
    }, []);

    const selectGroup = useCallback(async (groupId) => {
        if (groupId === null || groupId === undefined || groupId === 0) {
            setSelectedGroup(null);
            return null;
        }
        const group = await getGroupDetails(groupId);
        setSelectedGroup(group);
        return group;
    }, []);
    

    const updateGroup = async (group) => {
        const updatedGroup = await putGroup(group.id, group.title, group.description, group.masterId, group.type, group.templateId, group.pictureStyle);
        if (updatedGroup) {
            setSelectedGroup(updatedGroup);
        }
        return updatedGroup;
    };

    const deleteGroup = useCallback(async (groupId) => {
        const message = await deleteGroupWithId(groupId);
        if (message) {
            setMyGroups(myGroups.filter( (myGroup) => myGroup.id !== groupId));
            setPublicGroups(publicGroups.filter( (publicGroup) => publicGroup.id !== groupId));
        }
        return message;
    }, [myGroups, publicGroups]);

    const generateInviteCode = useCallback(async () => {
        const code = await postGenerateInviteCode(selectedGroup.id);
        if (code){
            setInviteCode(code);
        }
        return code;
    }, [selectedGroup]);

    const transferCreditsToGroup = async (credits) => {
        const thisGroupCredits = await postTransferCreditsToGroup(selectedGroup.id, credits);
        if (thisGroupCredits) {
            setGroupCredits(thisGroupCredits);
            currentUser.credits -= credits;
        }
        return groupCredits;
    };

    const transferCreditsToMember = async (memberId, credits) => {
        const thisMemberCredits = await postTransferCreditsToMember(selectedGroup.id, memberId, credits);
        if (thisMemberCredits) {
            setGroupCredits(groupCredits => groupCredits - credits);
            setSelectedGroup(selectedGroup => ({...selectedGroup, Members: selectedGroup.Members.map(member => member.id === memberId ? {...member, credits: thisMemberCredits} : member)}));
        }
        return thisMemberCredits;
    };

    const generateGroupBackground = useCallback(async () => {
        const file = await postGenerateGroupBackground(selectedGroup.id);
        if (file) {
            setGroupFiles(groupFiles => [file, ...groupFiles]);
        }
        return file;
    }, [selectedGroup]);

    const createDescriptionDocument = useCallback(async (title, files) => {
        const newFile = await postDescriptionDocument(selectedGroup.id, title, files);
        if (newFile) {
            setGroupFiles(groupFiles => [newFile, ...groupFiles]);
        }
        return newFile;
    }, [
        selectedGroup
    ]);

    const removeDescriptionDocument = useCallback(async (fileId) => {
        const message = await deleteDescriptionDocument(selectedGroup.id, fileId);
        if (message) {
            refreshLatestDocuments(selectedGroup.id);
            setGroupFiles(groupFiles.filter(file => file.id !== fileId));
        }
        return message;
    }, [
        refreshLatestDocuments,
        selectedGroup,
        groupFiles
    ]);

    useEffect(() => {
        if (selectedGroup) {
            setVariables(selectedGroup);            
            setMyGroups(myGroups => myGroups.map(group => group.id === selectedGroup.id ? selectedGroup : group));
            setPublicGroups(publicGroups => publicGroups.map(group => group.id === selectedGroup.id ? selectedGroup : group));
        }
    }, [selectedGroup, setVariables]);

    useEffect(() => {
        if (currentUser) {
            if (myGroups.length === 0) {
                getCurrentUserGroups().then(groups => setMyGroups(groups));
            }
            if (publicGroups.length === 0) {
                getPublicGroups().then(groups => setPublicGroups(groups));
            }
        }
    }, [currentUser, myGroups.length, publicGroups.length]);

    useEffect(() => {
        if (selectedTemplate?.id === groupTemplate?.id) {
            setGroupTemplate(selectedTemplate);
        }
    }, [selectedTemplate, groupTemplate]);

    return (
        <GroupsContext.Provider value={{
            isMember,
            isMaster,
            publicGroups,
            createGroup,
            groupTemplate,
            groupFiles,
            myGroups,
            selectGroup,generateGroupBackground,transferCreditsToGroup,transferCreditsToMember,
            selectedGroup,
            inviteCode,
            joinGroup,
            generateInviteCode,
            groupMembers,groupCredits,
            deleteGroup,
            refreshLatestDocuments,
            updateGroup,
            createDescriptionDocument,
            removeDescriptionDocument,
            latestDocuments}}>
        {children}
        </GroupsContext.Provider>
    );
};